import { MutationOptions } from '@apollo/client';
import { gql } from '@/__generated__/gateway';
import {
  ReadNotificationMutation,
  ReadNotificationMutationVariables,
  ReadNotificationsInput,
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables,
  SeenUserNotificationsMutation,
  SeenUserNotificationsMutationVariables
} from '@/__generated__/gateway/graphql';

export const markNotificationAsReadMutation = gql(`
  mutation ReadNotification($notificationId: ID!) {
    notification {
      readNotification(notification_id: $notificationId) {
        success
      }
    }
  }
`);

export const mutateReadNotification = (
  notificationId: string
): MutationOptions<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
> => ({
  mutation: markNotificationAsReadMutation,
  variables: { notificationId }
});

export const markNotificationsAsReadMutation = gql(`
  mutation ReadNotifications($input: ReadNotificationsInput) {
    notification {
      readNotifications(input: $input) {
        success
      }
    }
  }
`);

export const mutateReadNotifications = (
  input: ReadNotificationsInput
): MutationOptions<
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables
> => ({
  mutation: markNotificationsAsReadMutation,
  variables: { input }
});

export const seenUserNotificationsMutation = gql(`
  mutation SeenUserNotifications {
    notification {
      seenUserNotifications {
        success
      }
    }
  }
`);

export const mutateSeenUserNotifications = (): MutationOptions<
  SeenUserNotificationsMutation,
  SeenUserNotificationsMutationVariables
> => ({
  mutation: seenUserNotificationsMutation
});

export const crafterProjectCreateMutation = gql(`
  mutation CrafterProjectCreate($input: ProjectCreateInput!) {
    crafter {
      userProjectCreate(input: $input) {
        craft_type
        created_at
        ends_at
        id
        image {
          alt
          name
          url
        }
        name
        note {
          content
          created_at
          id
          updated_at
        }
        started_at
        state
        updated_at
      }
    }
  }
`);

export const crafterProjectFileUploadMutation = gql(`
  mutation CrafterProjectFileUpload($input: FileUploadCreateInput!) {
    crafter {
      fileUploadCreate(input: $input) {
        file_identifier
        upload_endpoint
      }
    }
  }
`);

export const crafterProjectUpdateMutation = gql(`
  mutation CrafterProjectUpdate($projectId: ID!, $input: ProjectUpdateInput) {
    crafter {
      userProjectUpdate(id: $projectId, input: $input) {
        id
        name
        craft_type
        state
        recipient
        started_at
        ends_at
      }
    }
  }
`);

export const crafterProjectProgressUpdateMutation = gql(`
  mutation CrafterProjectProgressUpdate($input: ProjectProgressUpdateInput!) {
    crafter {
      userProjectUpdateProgress(input: $input) {
        id
        content
        rows
        images {
          name
          alt
          url
        }
        created_at
        updated_at
      }
    }
  }
`);

export const createCrafterSurveyMutation = gql(`
  mutation CrafterCreateSurveyMutation($input: SurveyResponseCreateInput!) {
    crafter {
      surveyResponseCreate(input: $input) {
        id
        survey {
          id
          name
          help_text
          questions {
            id
            text
            help_text
            required
            options {
              id
              text
              order
            }
            type
            order
          }
        }
      }
    }
  }
`);

export const saveCrafterSurveyMutation = gql(`
  mutation CrafterSaveSurveyMutation($input: SurveyResponseSaveInput!) {
    crafter {
      surveyResponseSave(input: $input) {
        success
      }
    }
  }
`);
